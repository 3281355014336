
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AboutMe = () => {

  return (
    <VerticalTimeline>

      {/* about me card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--facts"
        contentStyle={{ background: '#3c6997', color: '#fdfffc' }}
        contentArrowStyle={{ borderRight: '7px solid  #3c6997' }}
        date=""
        iconStyle={{ background: '#92374d', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="question-circle" size="2x" pull="left" />}
      >
        <h3 className="vertical-timeline-element-title">About Me</h3>

        <p>
          I am a technically sophisticated computer science professional, with a proven history of effective data extraction, business analysis, automated scripting, RESTful APIs, and software development.
          Being proficient in coding languages is both a necessity and something I enjoy, and I use that fluency in building useful applications and websites, as wells as conducting data studies in Python.
          Collaboration and effectively directing multiple tasks are incredibly important to me, and I readily master innovative software and tools.
              </p>
      </VerticalTimelineElement>

      {/* technical proficiencies card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--facts"
        contentStyle={{ background: '#fdfffc', color: '#111716' }}
        contentArrowStyle={{ borderRight: '7px solid  #fdfffc' }}
        date=""
        iconStyle={{ background: '#3c6997', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="laptop-code" size="2x" pull="" viewBox="175 0 640 512" />}
      >
        <h3 className="timeline-title">Technical Proficiencies</h3>
        <h4 className="timeline-subtitle">Languages &amp; Frameworks:</h4>
        <p className="timeline-content">APEX, JavaScript, HTML5/CSS, SASS, RESTful API, React, Node.Js, jQuery, Java, React,
SQL, SOQL, Python, Swift, C, Visualforce</p>
        <h4 className="timeline-subtitle">Software and Environments:</h4>
        <p className="timeline-content">
          Insomnia, IntelliJ, Microsoft Office 365, Plex ERP, Pycharm, Relius, RStudio, SAP Crystal Reports, VS Code, Visual Studio, WebStorm, Xcode
              </p>
        <h4 className="timeline-subtitle">Platforms:</h4>
        <p className="timeline-content-last">
          Linux, Windows, and macOS
              </p>
      </VerticalTimelineElement>

      {/* current job card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#fdfffc', color: '#111716' }}
        contentArrowStyle={{ borderRight: '7px solid  #fdfffc' }}
        date="2021-present"
        iconStyle={{ background: '#3c6997', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="building" size="2x" className="building" viewBox="50 0 448 512" />}
      >
        <h3 className="timeline-title-close">Application Engineer</h3>
        <h5 className="timeline-subtitle">Steelcase</h5>
        <h5 className="timeline-subtitle"><FontAwesomeIcon icon="map-marker-alt" /> Grand Rapids, Michigan</h5>
        <p className="timeline-overview">
        Proficient at working in both the front and back-end, though the focus is on Ui/Ux improvements and designs for
        websites that cater to both consumers and other businesses Steelcase supports. Upgrade sites with new styles
        provided by the design team, as well as implementing original ideas. Help direct the design guidelines as well as
        maintain the design framework implemented at Steelcase.{'\n'}
        </p>
        <h6 className="timeline-subtitle">Key Achievements:</h6>
        <ul className="timeline-content">
          <li>Implemented a modern redesign of Steelcase's emails</li>
          <li>
          Support needs of business partners and implement their requests
                </li>
        </ul>
      </VerticalTimelineElement>

      {/* previous job 1 card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#fdfffc', color: '#111716' }}
        contentArrowStyle={{ borderRight: '7px solid  #fdfffc' }}
        date="2019-2021"
        iconStyle={{ background: '#3c6997', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="building" size="2x" className="building" viewBox="50 0 448 512" />}
      >
        <h3 className="timeline-title-close">Software Engineer</h3>
        <h5 className="timeline-subtitle">LT Trust</h5>
        <h5 className="timeline-subtitle"><FontAwesomeIcon icon="map-marker-alt" /> Denver, Colorado</h5>
        <p className="timeline-overview">
          Work in both front-end and back-end environments. Create reports for the business unit with SQL and Crystal reports.
          Set up recurring jobs to e-mail or FTP files to the interested party.
          Transform files through JavaScript and Python processes.
                Create new pages, integrations, and content for the website.{'\n'}
        </p>
        <h6 className="timeline-subtitle">Key Achievements:</h6>
        <ul className="timeline-content">
          <li>
            Implemented a retirement calculator that pulls the client and their company for the website
                </li>
          <li>
            Created dynamic content showing investment information depending on the model chosen
                </li>
          <li>
            Support the Business Unit through file ETL and automated reporting
                </li>
          <li>
            Continuously document processes and keep code up to date with version control
                </li>
        </ul>
      </VerticalTimelineElement>

      {/* previous job 2 card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#fdfffc', color: '#111716' }}
        contentArrowStyle={{ borderRight: '7px solid  #fdfffc' }}
        date="2018-2019"
        iconStyle={{ background: '#3c6997', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="industry" size="2x" pull="left" />}
      >
        <h3 className="timeline-title-close">Business Systems Analyst</h3>
        <h5 className="timeline-subtitle">Challenge Manufacturing</h5>
        <h5 className="timeline-subtitle"><FontAwesomeIcon icon="map-marker-alt" /> Walker, Michigan</h5>
        <p className="timeline-overview">
          Orchestrated monthly cost analysis on IT Department’s spending.
          Maintained a SOTI Mobicontrol server with over 100 assets.
          Linked our asset management system to a trello board through their API for easier tracking and provisioning.
          Performed monthly time studies of conference rooms to inform management of utilization.
              </p>
        <h6 className="timeline-subtitle">Key Achievements:</h6>
        <ul className="timeline-content">
          <li>
            Saved the company over $100,000 through software development and automated scripting
                </li>
          <li>
            Singlehandedly developed the company's Intranet with active file hosting and an admin page for IT users to display messages to the company's employees.
                </li>
          <li>
            Created conference room displays that sync with Outlook Calendars.
                </li>
          <li>
            Completed a month-long data study to improve efficiency of production floor machines.
                </li>
          <li>
            Supported the PLEX PCN Split of the company’s two largest plants by performing data separation on millions of Employee, Machine, Purchasing, Locations, Inventory, and MRO entries.
                </li>
        </ul>
      </VerticalTimelineElement>

      {/* Education card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{ background: '#92374d', color: '#fdfffc' }}
        contentArrowStyle={{ borderRight: '7px solid  #92374d' }}
        date="2018"
        iconStyle={{ background: '#fdfffc', color: '#3c6997' }}
        icon={<FontAwesomeIcon icon="university" size="2x" pull="left" />}
      >
        <h3 className="timeline-title-close">Grand Valley State University</h3>
        <h5 className="timeline-subtitle">Bachelor of Science in Computer Science</h5>

      </VerticalTimelineElement>

      {/* internship card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#fdfffc', color: '#111716' }}
        contentArrowStyle={{ borderRight: '7px solid  #fdfffc' }}
        date="2017-2018"
        iconStyle={{ background: '#3c6997', color: '#fdfffc' }}

        icon={<FontAwesomeIcon icon="hourglass-start" size="2x" pull="" />}
      >
        <h3 className="timeline-title-close">IT Intern</h3>
        <h5 className="timeline-subtitle">Challenge Manufacturing</h5>
        <h5 className="timeline-subtitle"><FontAwesomeIcon icon="map-marker-alt" /> Walker, Michigan</h5>
        <p className="timeline-overview">
          Excelled in back-end development and pulling data for company projects.
          Worked with multiple departments to streamline their daily operations as well as improve their data and visibility.
          Gained experience in professional memo writing as well as professional demeanor in meetings and presentations.
              </p>
        <h6 className="timeline-subtitle">Key Achievements:</h6>
        <ul className="timeline-content">
          <li>
            Developed a live data dashboard for the Executive Vice President.
                </li>
          <li>
            Developed an automated email survey for the help desk.
                </li>

        </ul>
      </VerticalTimelineElement>

      {/* fun facts card */}
      <VerticalTimelineElement
        className="vertical-timeline-element--facts"
        contentStyle={{ background: '#3c6997', color: '#fdfffc' }}
        contentArrowStyle={{ borderRight: '7px solid  #3c6997' }}
        date=""
        iconStyle={{ background: '#92374d', color: '#fdfffc' }}
        icon={<FontAwesomeIcon icon="jedi" size="2x" pull="" viewBox="125 0 576 512" />}
      >

        <h3 className="timeline-title-close">Fun Facts</h3>
  
          <ul className="funFacts">
            <li className="funFacts">
              <a href="https://copa-vida.com/" className="linkColorFixWhite listIcons" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="coffee" size="2x" className="mobileIcons" /> </a>
              <h3 className="mobileSpacing">Favorite Drink</h3>
              <p className="indent"><FontAwesomeIcon icon="arrow-right" size="1x" /> I love all coffee, but my favorite is an Iced Shakerato from Copa Vida!</p>
            </li>
            <li className="funFacts">
              <a href="https://www.millersbar.com/" className="linkColorFixWhite listIcons" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="hamburger" size="2x" /></a>
              <h3 className="mobileSpacing"> Favorite Food</h3>
              <p className="indent"><FontAwesomeIcon icon="arrow-right" size="1x" /> I can't decide between pizza and burgers...</p>
            </li>
            <li className="funFacts">
              <a href="https://www.python.org/" className="linkColorFixWhite listIcons" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="code" size="2x" /></a>
              <h3 className="mobileSpacing"> Favorite Language</h3>
              <p className="indent"><FontAwesomeIcon icon="arrow-right" size="1x" /> I flip between Python and JavaScript</p>
            </li>
            <li className="funFacts">
              <a href="https://www.nintendo.com/" className="linkColorFixWhite listIcons" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="gamepad" size="2x" /></a>
              <h3 className="mobileSpacing"> Favorite Game</h3>
              <p className="indent"><FontAwesomeIcon icon="arrow-right" size="1x" /> I have a lot, but currently The Last of Us Part Two, Breath of the Wild, and Animal Crossing</p>
            </li>
          </ul>
        

      </VerticalTimelineElement>

      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<FontAwesomeIcon icon="check" size="2x" pull="left" />}
      />
    </VerticalTimeline>


  )
}

export default AboutMe;