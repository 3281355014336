import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './Components/Home';
import AboutMe from './Components/AboutMe';
import MyWork from './Components/MyWork';
import CustomNavBar from './Components/CustomNavBar';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLaughBeam, faCoffee, faHome, faCommentAlt, faLaptopHouse, faQuestionCircle,
  faLaptopCode, faBuilding, faIndustry, faUniversity, faHamburger, faMapMarkerAlt,
  faHourglassStart, faGamepad, faCheck, faTools, faEnvelopeOpenText, faCode, faDice, faArrowRight,
  faJedi, faPaintBrush, faTablet, faMobileAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(faLaughBeam, faCoffee, faHome, faCommentAlt, faLaptopHouse, faQuestionCircle,
  faLaptopCode, faBuilding, faIndustry, faUniversity, faHamburger, faMapMarkerAlt,
  faHourglassStart, faGamepad, faCheck, faTools, faEnvelopeOpenText, faCode, faDice, faArrowRight,
  faJedi, faPaintBrush, faTablet, faMobileAlt)




function App() {
  return (
    <div>
      <Router basename="/">
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
          integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
          crossorigin="anonymous"
        />
        <CustomNavBar />
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/aboutme" component={AboutMe} />
          <Route exact path="/mywork" component={MyWork} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>

  );
}

export default App;