
import React from 'react';
import '../styling/customHome.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "typeface-roboto";
import {  MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBView } from 'mdbreact';
import dogndI from '../images/overlooking.jpeg'
import './konamiCode.js';





const Home = () => {
    const motto = ['I am a web developer and software engineer.', 'You dream it, I build it.'];

   

    return (
        
        <div class="wrapper">
        <script crossorigin src="https://smtpjs.com/v3/smtp.js"></script>

  <section id="home" class="sec-main">
    <h1 class="main-heading">I make cool stuff online.</h1>
  </section>

  <section id="about" class="sec-about">

  <MDBRow center>
      <MDBCol md="4">
       
        <MDBCard wide cascade>
          <MDBView cascade>
          <a href="https://www.linkedin.com/in/milltyle/" target="_blank" rel="noopener noreferrer">
            <MDBCardImage
              hover
              overlay='white-slight'
              className='card-img-top'
              size='1px'
              src={dogndI}
              alt=''
            />
            </a>
          </MDBView>

          <MDBCardBody cascade className='text-center'>
            <MDBCardTitle className='card-title'>
              <strong>Tyler J. Miller</strong>
            </MDBCardTitle>

            <p className='font-weight-bold blue-text'>Software Engineer</p>

            <MDBCardText className="splitline">
            {motto.join('\n')}{' '}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
       
      </MDBCol>
   
    </MDBRow>
  </section>
  <section id="services" class="sec-services">
    <div class="container">
      <h1>Services</h1>

      <hr />

      <div class="row">
        <div class="col-sm-4">
        <FontAwesomeIcon icon="paint-brush" size="4x"/>

          <h2 class="h3">Freedom of Design</h2>

          <p>You won't need pre-made templates anymore. Let's build something that's truly yours.</p>
        </div>

        <div class="col-sm-4">
        <FontAwesomeIcon icon="laptop-code" size="4x"/>

          <h2 class="h3">Cleanliness</h2>

          <p>All projects include valid code, proper syntax, and easy-to-follow comments, which results in beautiful designs. </p>
        </div>

        <div class="col-sm-4">
        <FontAwesomeIcon icon="mobile-alt" size="4x"/>

          <h2 class="h3">Always Responsive</h2>

          <p>Compatability is the name of the game, and you can rest assured knowing I test every site on a variety of devices and resolutions.</p>
        </div>
      </div>
    </div>
  </section>

 

  

  <section id="contact" class="sec-contact">
    <div class="container">
      <h1>Contact Me</h1>
       <a href="mailto:tyler@tylerjmiller.com"> <FontAwesomeIcon className="emailIcon" icon="envelope-open-text" size="4x" /> </a>
      <hr />

      <div class="row">
        <div class="col-sm-4 offset-sm-4 col-md-4 offset-md-4">
          {/* <form class="center-block" action="#" method="post">
            <div class="form-group">
              <label class="sr-only" for="inputName">Full name</label>

              <input id="inputName" class="form-control" type="text" placeholder="Tyler Miller" required />
            </div>

            <div class="form-group">
              <label for="inputMail" class="sr-only">Email Address</label>

              <input id="inputMail" class="form-control" type="email" placeholder="tyler.miller@mail.com" required />
            </div>

            <div class="form-group">
              <label for="inputMessage" class="sr-only">Your Message</label>

              <textarea id="inputMessage" class="form-control" name="message" cols="30" rows="8" required></textarea>
            </div>

            <div class="form-group">
              <button class="btn btn-default center-block" type="submit" value="Hire me">Hire me</button>
            </div>
          </form> */}
          
        </div>
      </div>
    </div>
  </section>




  <footer id="footer">
    <div class="container">
      
            <FontAwesomeIcon className="smiley" icon="laugh-beam" size="4x"/>
     
    </div>
  </footer>
</div>
    )

}




export default Home;