
import React, { Component } from 'react';
import {Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import  logo  from "../myself_circle.png"
import '../styling/custom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

    const navbar = {
    backgroundColor: '#111716'
};

export default class CustomNavBar extends Component {

    render() {
        return(
            <Navbar collapseOnSelect expand="lg" className="nav-color link-coloring navbar-dark " style={navbar} >
             <Navbar.Brand href="#home">
                <img
                    alt=""
                    src= {logo}
                    width="80"
                    height="80"
                    className="d-inline-block align-top"
                />
                <p className="speech">Tyler J. Miller</p>
                </Navbar.Brand>
                
                
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link eventKey="1" as={Link} to="/" className="Nav-text"><FontAwesomeIcon icon="home"/> Home </Nav.Link>
                    <Nav.Link eventKey="2" as={Link} to="aboutme" className="Nav-text"><FontAwesomeIcon icon="comment-alt"/> About Me</Nav.Link>
                    {/* <Nav.Link eventKey="3" as={Link} to="mywork" className="Nav-text"><FontAwesomeIcon icon="laptop-house"/> My Work</Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
       
        )
    }
}