
import React from 'react';
import '../styling/custom.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MyWork = () => {

        return(
            <div className="text-center">
            <h1 className="cursive_light text-center">Hello,</h1>
            <h2 className="roboto_light">this page is under construction</h2>
            <FontAwesomeIcon icon="tools" className="laughBeam" />
            {/* <i style="font-weight: bold; color:#FDFFFC; font-size:70px;" class="fas fa-laugh-beam"></i> */}
        </div>
        )
}

export default MyWork;